<!--
 Copyright 2022 Semantic STEP Technology GmbH, Germany & DCT Co., Ltd. Tianjin, China
-->

<template>
  <div>
    <em>Authorized access only</em>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>